@import 'Colours.scss';
/*!
 * Bootstrap Grid v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */


 .row.row {
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;

  & > * {
    padding: 0;
  }
}
 .form-control {
  display: block;
  width: 100%;
  height: 2.9em;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #303030;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  &.file-input{
    padding-right: 12rem !important;
    background-position: right 10rem center !important;
  }
}


.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  background-color: transparent;
  color: white;
}
