@import 'Colours.scss';
@import 'Utils.scss';

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 916px,
  xl: 1300px,
  xxl: 1500px
);

@import '~bootstrap/scss/bootstrap';
html,
body {
  max-width: 100vw;
  background-color: #F8F9FA;

  @media only print  {
    background-color: #fff;
    width: 100%;
    max-width: 1300px;
  }
}

body {
  color: var(--foreground);
  background: var(--background);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

#main-cont,
#main,
.footer {
  outline: none;
}

#widget-button {
  @media print {
    display: none !important;
  }
  height: 2.3rem !important;
  width: 2.3rem !important;
}

#iframe-widget {
  @media print {
    display: none !important;
  }
}

#widget-button i {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 1em !important;
  margin: auto !important;
  width: 0.75em;
  height: 1em;
  content: url('../public/images/question-solid.svg')
}

#skip-to-nav-div {
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, 100%);
  transition: transform 0.3s;
  text-align: center;
  background-color: $paysimply-blue;
  padding: 5px 3px;
  display: inline-block !important;
  border-radius: 5px;
  margin-left: 2px;
  @media print {
    display: none !important;
  }
  &:focus-within {
    transform: translate(-50%, 10%);
  }
  button {
    background-color: transparent;
    color: white;
    padding: 5px;
    margin: 0 2.5px;
    border-radius: 5px;
    border: 1px solid transparent !important;
    font-size: 15pt;
    font-weight: normal;

      &:focus {
        border: 1px solid #ffff !important;
      }
      &:hover {
        background-color: $paysimply-blue-hover;
      }
  }
}

.react-datepicker__day--keyboard-selected {
  color: #fff !important;
}

.react-datepicker__today-button {
  display: none;
  background-color: $paysimply-blue !important;
  font-family: 'Rubik', sans-serif;
  color: white;
  font-weight: 500 !important;
  border-radius: 0 0 .3rem .3rem;
  width: 243px;
}

// Datepicker Styles

.datepickerBtn {
  font-size: 1rem;
  min-width: 0.3rem !important;
  width: 0.3rem;
  height: auto!important;
  border-radius: 3px;
  color: black !important;
  background-color: $paysimply-input-items-background !important;
  border: 1px solid $paysimply-input-items-border-color !important;
  box-shadow: none !important;
  margin-top: 5px;

  @media (max-width: 360px) {
    padding: 5px 25px !important;
  }
}

.react-datepicker {
  border: none !important;
  outline: none !important;
  font-family: "Rubik", sans-serif;
  box-shadow: 0px 4px 16px 0px #fff1;
  --border-color: #888;
  
  /* hide input field within datepicker component behind our react-final form field */
  &-wrapper {
    position: absolute;
    visibility: hidden;
  }
  
  /* stops weird animation between input and button */
  &__tab-loop {
    margin-left: 0 !important;
  }

  &__navigation-icon::before {
    top: 8px !important;
    border-color: #ccc;
  }

  &__navigation {
    min-width: 0.3rem !important;
}

  &__header {

    select {
      background-position: right 0.5rem center;
      height: auto;
    }
  }

  &__current-month {
    font-weight: 500;
    line-height: 1.5rem;
  }

  &__month-container {
    border: 1px solid var(--border-color);
  }

  &__day {
    color: white;
    font-weight: 400;

    &-name {
      color: white;
      font-weight: 400;
    }

    &--today {
      font-weight: 500;
    }

    &--selected,
    &--keyboard-selected {
      background-color: $paysimply-blue !important;
      &:hover {
        background-color: rgb($paysimply-blue, 0.8) !important;
      }
    }

    &--outside-month {
      color: #888;
    }

    &--disabled:hover {
      background-color: transparent !important;
    }
  }
  
  /* fixing triangle on datepicker popup */
  &__triangle {
    &::after {
      left: -18px !important;
      bottom: 1.5px !important;
    }
    &::before {
      margin-left: -10px;
      bottom: 1px !important;
      border-top-color: var(--border-color) !important;
      border-bottom-color: var(--border-color) !important;
    }

    .react-datepicker-popper[data-placement=bottom-start] & {
      &::after {
        top: 1.5px !important;
      }
      &::before {
        top: 0.5px !important;
      }
    }
  }
}

// End of Datepicker Styles

.visuallyHidden {
  position: absolute;
  left: -10000px;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.form-control{
  z-index: 0 !important;
}

.input-group-text {
  background-color: $paysimply-input-items-background;
  border-color: $paysimply-input-items-border-color;
}

@media only print {
  @page {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    // https://github.com/Payment-Source/PaySimply-Web/pull/131#discussion_r1922702361
    @top-right {
      content: ""; // removes content from print header
    }
    @bottom-left {
      content: ""; // removes content from print footer
    }
  }

  html, body {
    width: 210mm; // A4 Paper width
  }
}

@media only print {
  .noPrint.printing {
    display: none !important;
  }
}

.onlyPrint {
  @media screen {
    display: none !important;
  }
  @media print {
    &:not(.printing) {
      display: none !important;
    }
  }
}

.banner-cont {
  text-align: center;
  background-color: $paysimply-blue;
  color: white;
  font-size: 1.1rem;
  width: 100%;
  padding: 15px 3rem;
  position: relative;

  @media only screen and (max-width: 660px) {
    text-align: left;
    padding: 15px 3rem 15px 1rem;
  }
    
  a {
    text-decoration: underline !important;
    &:hover {
      color: #dddddd;
    }
  }

  b {
    font-weight: 600;
  }

  .dismissBtn {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    position: absolute;
    
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    &:hover svg {
      opacity: 1;
    }
    &:focus {
      outline: auto 1px;
    }

    svg {
      color: white;
      opacity: 0.75;
    }
  }
}